import React, { Component, Fragment, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import ChatSection from './ChatSection';
import { ReactComponent as DoubleArrowIcon } from '../../svg/doubleArrowUp.svg';
import { ReactComponent as HeartIcon } from '../../svg/heart.svg';
import { ReactComponent as ChatIcon } from '../../svg/chat.svg';
import CommentBox from './CommentBox';
import DialogBox from '../DialogBox';
import Filters from '../Filters';
import Note from './Note.component';
import axios from 'axios';
import AuthService from '../../services/AuthService';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  scroller: {
    padding: theme.spacing(2),
    display: 'flex',
    overflowX: 'scroll',
  },
  chip: {
    margin: theme.spacing(0, 1),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  root: {
    flexGrow: 1,
    marginTop: '200px !important',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn:false,
      username:'',
      userId:'',
      open: false,
      toggleChat: true,
      feed: [],
      feedCopy: [],
      thisComment: null,
      dialogBoxState: false,
      note: {},
      note_id: '',
      likedNotes: [],
    };
    this.getNotes = this.getNotes.bind(this);
    this.getLikedNotes = this.getLikedNotes.bind(this);
  }

  componentDidMount() {
    let userId="";
    const userDetails = AuthService.getCurrentUser();
    if(userDetails){
      userId = userDetails.id;
      this.setState({isLoggedIn: true, userId:userId, username:userDetails.username})
      // console.log("USERID" + userId)
      this.getLikedNotes(userId);
    }
    this.getNotes();
  }

  
  // componentDidUpdate(prevProps, prevState){
  //   if(prevState.open !==this.state.open){
  //     this.getNotes();
  //   }
  // }

  async getLikedNotes(userId){
    axios
    .post("https://noted-back.herokuapp.com/api/auth/likedNotes", {
      userId: userId
    })
      .then((res) => {
        // console.log(res);
        this.setState({likedNotes : res.data.likedNotes})
      });
  }

  async getNotes() {
    await axios
      .get('https://noted-back.herokuapp.com/api/auth/getnotes')
      .then( (response) => {
        // console.log("GetNotes API call triggered !!")
         this.setState({
          feed: response.data,
          feedCopy: response.data,
        });
        // console.log(this.state.feed);
        // return response.data;
      });
  }

  handleFilterSet = (filterTopic) => {
    let { feed, feedCopy } = this.state;

    if (filterTopic) {
      feed = feedCopy.filter(({ topic }) => topic === filterTopic);

      this.setState({ ...this.state, feed });
    } else {
      this.setState({ ...this.state, feed: feedCopy });
    }
  };

  handleDialogBoxOpen = (fe) => {
    this.setState({ dialogBoxState: true, note: fe });
  };
  handleDialogBoxClose = () => {
    this.setState({ dialogBoxState: false, note: {} });
  };
  handleOpen = (event, id) => {
    event.stopPropagation();
    this.setState({ thisComment: `${id}` });
    this.setState({ open: true });
    // setOpen(true);
  };

  handleClose = (event) => {
    // setOpen(false);
    event.stopPropagation();
    this.setState({ open: false });
  };

  ChatToggler = (event, id) => {
    event.stopPropagation();
    if (this.state.note_id == id) {
      this.setState({ toggleChat: false, note_id: '' });
      return;
    }
    this.setState({ note_id: id, toggleChat: true });
    // this.state.toggleChat
    //   ? this.setState({ toggleChat: true })
    //   : this.setState({ toggleChat: true });
  };
  postLiked(event, userId, notesId, liked) {
    event.stopPropagation();
    // console.log("post api is called")
    axios
      .post('https://noted-back.herokuapp.com/api/auth/liked', {
        notesId: notesId,
        userId: userId,
        isLiked: !liked,
      })
      .then((res) => {
        // console.log(res);
        this.getNotes();
        this.getLikedNotes(userId);
      });
  }
  render() {
    const { feed } = this.state;
    const { thisComment, isLoggedIn } = this.state;
    // const {username, id:userId} = null;
    // const {username, id:userId} =  AuthService.getCurrentUser();
    // if(isLoggedIn){
    //   const {username, id:userId} =  AuthService.getCurrentUser();
    // }
    

    let random =
      feed.length > 0 ? (
        feed.map((fe, index) => {
          const isNotesLiked = this.state.likedNotes.includes(fe._id);
          return (

            <Grid key={index} item xs={12} sm={6}>
              <CommentBox
                open={this.state.open}
                handleClose={this.handleClose}
                handleOpen={this.handleOpen}
                id={this.state.thisComment}
                callback={this.getNotes}
                userId= {this.state.username}
              />
              <ul
                onClick={() => {
                  this.handleDialogBoxOpen(fe);
                  // this.props.history.push('/view-note');
                }}
                key={index}>
                <main className='main'>
                  {/* <CommentBox
                    open={this.state.open}
                    handleClose={this.handleClose}
                    // handleOpen={this.handleOpen}
                    // id={this.state.thisComment}
                  /> */}

                  <div className='mainContent'>
                    <Note note={fe} />
                    <div className='username-opt'>
                      <span>
                        <b>Uploaded by user:</b> {fe.userid} 
                      </span>
                      {this.state.isLoggedIn ?
                      <ul className='listText'>
                        <li
                          style={{
                            display: 'flex',
                            transform:
                              this.state.note_id == fe._id
                                ? 'rotate(180deg)'
                                : 'rotate(0deg)',
                          }}
                          
                          onClick={(event) => this.ChatToggler(event, fe._id)}>
                          <DoubleArrowIcon />
                        </li>

                        <li>
                          <HeartIcon
                            className={`${ isNotesLiked ? 'icon-button' : ''}`}
                            onClick={(event) =>
                              this.postLiked(event, this.state.userId, fe._id, isNotesLiked)
                            }
                          />
                        </li>
                        <li>
                          <ChatIcon 
                            onClick={(event) => this.handleOpen(event, fe._id)}
                          />
                          {fe?.comments?.length && (
                            <sup>{fe?.comments?.length}</sup>
                          )}
                        </li> 

                      </ul>
                      : ""}
                    </div>
                    {/* <div>{fe.comments}</div> */}
                    <ChatSection
                      data={fe.comments}
                      className={
                        fe._id == this.state.note_id && this.state.toggleChat
                          ? 'ChatShow'
                          : 'ChatHide'
                      }
                    />
                  </div>
                  {/* <div className='bannerArea'>
                    <div className='ad-div'>
                      <span className='text-ad'>AD</span>
                      <h1 className='add-heading'>LOREM IPSUM</h1>
                      <img src='assets/img/add-graphics.png' alt='' />
                    </div>
                  </div> */}
                </main>
              </ul>
            </Grid>
          );
        })
      ) : (
          <Box
            width={'100%'}
            padding={15}
            display='flex'
            justifyContent='center'
            alignItems='centers'>
            <h5>No feed items found</h5>
          </Box>
        );
    return (
      <Box width={'100%'}>
        <DialogBox
          open={this.state.dialogBoxState}
          handleClose={this.handleDialogBoxClose}
          note={this.state.note}
        />
        <Filters onSetFilter={this.handleFilterSet} notes={feed} />
        <div className={this.props.classes.root} style={{ marginTop: '200px' }}>
          <Grid container spacing={3}>
            {random}
          </Grid>
        </div>
      </Box>
    );
  }
}
export default withRouter(withStyles(useStyles)(Main));
