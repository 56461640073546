import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { Box, Button, InputLabel } from '@material-ui/core'
import { Link } from 'react-router-dom'
import AuthService from "../../services/AuthService"
import { useHistory } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: '1rem',
        '& .MuiGrid-root.MuiGrid-item': {
            margin: 'auto'
        }
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    containerGrid: {

    },
    pageTitle: {
        color: '#444444'
    },
    select: {
        marginLeft: '1rem',
        '& select': {
            padding: '10px 15px'
        }
    },
    submitBtn: {
        background: '#74b9be!important',
        color: '#fff!important',
        minWidth: '150px',
        height: 40
    },
    cont: {
        '& .Mui-focused': {
            color: '#74b9be'
        },
        '& .Mui-focused fieldset': {
            borderColor: '#74b9be!important'
        }
    },
    form: {
        maxWidth: 700,
        margin: 'auto'
    }
}))

function loginUser(credentials){
    const tken = AuthService.login(credentials.username, credentials.password).then(
        () =>
        error => {
          const resMessage = error.toString();
        }
      );
    //   console.log(" TKen is "+tken)
      return tken;
}

const Login = () => {
    const classes = useStyles()
    const [username, setUsername] = useState()
    const [password, setPassword] = useState()
    const [token, setToken] = useState()
    const history = useHistory();

    const handleSubmit = async e => {
        e.preventDefault();
        await loginUser({
          username,
          password
        }).then( response => {
            // console.log(" login Response is "+ response.username)
            console.table(response)
        });
        // token = tken;
        // setToken(tken);
        if(AuthService.isAuthenticated())
        {
            history.push('/');
        }
      }

    return (
        <div className={classes.root}>

            <Box textAlign="center" padding="1rem" marginBottom="1rem">
                <h1 className={classes.pageTitle}>
                    Login
                </h1>
            </Box>

            <form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid className={classes.cont} container spacing={3} >
                    <Grid alignContent="center" item xs={12} sm={12}>
                        <TextField fullWidth id="outlined-basic" label="User Name" variant="outlined" onChange={e => setUsername(e.target.value)}/>
                    </Grid>
                    <Grid alignContent="center" item xs={12} sm={12}>
                        <TextField fullWidth type="password" id="outlined-basic" label="Password" variant="outlined" onChange={e => setPassword(e.target.value)}/>
                    </Grid>
                    <Grid alignContent="center" item xs={12} sm={12} >
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <Button className={classes.submitBtn} type="submit">
                                Login
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <small>Don't have an account <Link to='/signup'> SignUp</Link> </small>

            </form>
        </div >
    )
}

export default Login
