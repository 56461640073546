import { React, useState } from 'react'
import { ReactComponent as TodoTitleIcon } from '../../svg/todo.svg'

const TodoList = () => {
    const initailState = [
        {
            task: 'Lorem ipsum dolor sit amet',
            isCompleted: false,

        },
        {
            task: 'Lorem ipsum dolor sit amet',
            isCompleted: false,

        },
        {
            task: 'Lorem ipsum dolor sit amet',
            isCompleted: false,

        },
        {
            task: 'Lorem ipsum dolor sit amet',
            isCompleted: false,

        },
        {
            task: 'Lorem ipsum dolor sit amet',
            isCompleted: true,

        },
        {
            task: 'Lorem ipsum dolor sit amet',
            isCompleted: true,

        },
    ]
    const [todos, setTodos] = useState(initailState)
    const clickHandler = (id) => {
        const _todos = [...todos]
        _todos[id].isCompleted = true
        setTodos(_todos)
    }

    return (
        <div className="todoList">
            <h2 className="todosHeading"> <TodoTitleIcon /> TO DO</h2>
            <ul className="todos">

                {todos.map((todo, index) => {
                    return (
                        <li key={todo.id} className={todo.isCompleted ? 'completed todoItem' : 'todoItem'} onClick={() => clickHandler(index)}>
                            <span>{todo.task} </span>
                            {todo.isCompleted ? <span className="checkCompteled">✔</span> : <span className="checkB"></span>}
                        </li>
                    )
                })}
            </ul>
            <div className="arrowDownDiv">
                <img src="./assets/img/icons/arrowDown.png" alt="" />
            </div>
        </div>
    )
}

export default TodoList