import {React, useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { Box, Button, InputLabel } from '@material-ui/core'
import Select from '@material-ui/core/Select'
// import NativeSelect from '@material-ui/core/NativeSelect'
import AuthService from "../../services/AuthService"
import {useHistory} from "react-router-dom"
import {toast} from 'react-toastify'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        // overflow: 'hidden'
        padding: '1rem',
        '& .MuiGrid-root.MuiGrid-item': {
            margin: 'auto'
        }
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    containerGrid: {

    },
    pageTitle: {
        color: '#444444'
    },
    select: {
        marginLeft: '1rem',
        '& select': {
            padding: '10px 15px'
        }
    },
    submitBtn: {
        background: '#74b9be!important',
        color: '#fff!important',
        minWidth: '150px',
        height: 40
    },
    cont: {
        '& .Mui-focused': {
            color: '#74b9be'
        },
        '& .Mui-focused fieldset': {
            borderColor: '#74b9be!important'
        }
    }
}))

function registerUser(credentials){
    const tken = AuthService.register(credentials.username, credentials.email, credentials.password).then(
        () =>
        error => {
          const resMessage = error.toString();
        }
      );
    //   console.log(" TKen is "+tken)
      return tken;
}

const SignUp = () => {
    const classes = useStyles()
    const [username, setUsername] = useState()
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const history = useHistory();

    const handleSubmit = async e => {
        e.preventDefault();
        await registerUser({
          username,
          email,
          password
        }).then( response => {
            // console.log(" login Response is "+ response.username)
            // console.table(response)
            toast.success("Verification Email Sent")
            history.push("/Login")
        }).catch(error => {
            // console.log(error.response.data.message)
            toast.error(error.response.data.message)
        })
        // token = tken;
        // setToken(tken);
      }


    return (
        <div className={classes.root}>

            <Box textAlign="center" padding="1rem" marginBottom="1rem">
                <h1 className={classes.pageTitle}>
                    SignUp
                </h1>
            </Box>



            <form className="form-c" noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid className={classes.cont} container spacing={3} >
                    <Grid alignContent="center" item xs={12} sm={12} md={6} lg={6} >
                        <TextField fullWidth id="outlined-basic" label="First Name" variant="outlined" />
                    </Grid>
                    <Grid alignContent="center" item xs={12} sm={12} md={6} lg={6}>
                        <TextField fullWidth id="outlined-basic" label="Last Name" variant="outlined" />
                    </Grid>

                    <Grid alignContent="center" item xs={12} sm={12} md={12} lg={12}>
                        <TextField fullWidth id="outlined-basic" label="User Name" variant="outlined" onChange={e => setUsername(e.target.value)}/>
                    </Grid>

                    <Grid alignContent="center" item xs={12} sm={12} >
                        <TextField fullWidth id="outlined-basic" label="Email" type="email" variant="outlined" onChange={e => setEmail(e.target.value)}/>
                    </Grid>
                    <Grid alignContent="center" item xs={12} sm={12} md={12} lg={12}>
                        <TextField fullWidth id="outlined-basic" label="Password" type="password" variant="outlined" onChange={e => setPassword(e.target.value)}/>
                    </Grid>
                    <Grid alignContent="center" item xs={12} sm={12} >
                        <Button className={classes.submitBtn} type="submit">
                            SignUp
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div >
    )
}

export default SignUp
