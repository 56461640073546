import React from 'react';
import SignUp from '../../components/Auth/SignUp';

const SignUpPage = () => {
  return (
    <div>
      <div style={{ paddingTop: '150px' }} className='NotesContainer'>
        <SignUp />
      </div>
    </div>
  );
};

export default SignUpPage;
