import {
  Grid,
  Typography,
  Box,
  Button,
  TextField,
  Container,
  CssBaseline,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  submitBtn: {
    background: '#74b9be!important',
    color: '#fff!important',
    minWidth: '150px',
    height: 40,
    marginTop: '20px',
  },
  textArea: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    color: 'black',
    padding: '10px',
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.00938em',
  },
}));
const ViewNote = () => {
  const classes = useStyles();
  const note = JSON.parse(localStorage.getItem('note'));
  const history = useHistory();
  if (!note) {
    history.push('/');
    return null;
  }
  const { topic, subtopic, description, fileData } = note;
  const handleChange = () => {};
  return (
    <Container style={{ paddingTop: '150px' }} component='main' maxWidth='lg'>
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component='h1' variant='h5'>
          View Note Information
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='topic'
            label='Topic'
            name='topic'
            autoComplete='topic'
            autoFocus
            value={topic}
          />
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='subTopic'
            label='Sub Topic'
            value={subtopic}
            id='subTopic'
            autoComplete='subTopic'
          />
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='description'
            label='Description'
            value={description}
            id='description'
            autoComplete='description'
          />

          <TextField
            id='outlined-multiline-static'
            label='File Data'
            multiline
            fullWidth
            className={classes.form}
            rows={10}
            defaultValue={fileData}
            variant='outlined'
          />
          <Grid alignContent='center' item xs={12} sm={12}>
            <Box display='flex' alignItems='center' justifyContent='center'>
              {/* <Button className={classes.submitBtn} type='submit'>
                Edit Note
              </Button> */}
            </Box>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default ViewNote;
