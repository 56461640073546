import React, {useEffect, useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import { Button } from '@material-ui/core'
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}))

function postComment (value) {
        axios.post('https://noted-back.herokuapp.com/api/auth/comment', 
        {  "id": value.id, "comment": value.comment, 'userid': value.userId})
        .then(res => {
            // console.log("Comment Response",res);  
        });
}

export default function CommentBox({ handleOpen, handleClose, open, id, callback, userId}) {
    // console.log(" This id is "+id)

    const [comment, setComment] = useState()
    const handleSubmit = async e => {
        e.preventDefault();
        await postComment({
           comment,
           id,
           userId
        });
        callback();
      }

    const classes = useStyles()
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <form className="commentForm" onSubmit={handleSubmit}>
                        <h3>Enter your comment</h3>
                        <textarea className="textarea" cols="30" rows="10" onChange={e => setComment(e.target.value)}></textarea>
                        <Button onClick={handleClose} type="submit"> Add </Button>
                    </form>
                </Fade>
            </Modal>
        </div>
    )
}
