import './App.css';
// import Login from './components/Auth/Login'
// import MyNotes from './components/myNotes/MyNotes'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ViewNote, Home, Login, SignUp, Profile, MyNotes } from './views';
import 'react-calendar/dist/Calendar.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {ToastContainer} from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import Header from './components/header/Header';
function App() {
  return (
    <Router>
      <Header />
      <ToastContainer 
        autoClose={1500}
        position="top-center"
        hideProgressBar={true}
        newestOnTop={true}
        pauseOnFocusLoss
      />
      <div className='App'>
        <Switch>
          <Route exact path='/login' component={Login} />
          <Route exact path='/signup' component={SignUp} />        
          <Route exact path='/notes' component={MyNotes} />
          <Route exact exact path='/view-note' component={ViewNote} />
          <Route exact path='/profile' component={Profile} />
          <Route exact path='/' component={Home} />
        </Switch>
      </div>
    </Router>
  );
}
export default App;
