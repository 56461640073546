import React from 'react'

const ChatSection = ({ data, className }) => {
    let random = data?.map((fe, index) => {
        return (
            <div key={index} className={`${className} chatSection`}>
                {
                    data[index].map((dataItm) => {
                        return (
                            <ul key = {index}>
                                <li>
                                    <span className="chatUserName">
                                        {dataItm.userid}:
                                    </span>
                                    <span className="chatText">
                                        {dataItm.comment}
                                    </span>
                                </li>
                            </ul>
                        )
                    })
                }
            </div>
        )
    })

    return (
        <React.Fragment>
            <div>
                {random}
            </div>
        </React.Fragment>
    )
}

export default ChatSection