import React from 'react';

import Profile from '../../components/Profile/Profile';

const ProfilePage = () => {
  return (
    <div>
      <div style={{ paddingTop: '150px' }} className='NotesContainer'>
        <Profile />
      </div>
    </div>
  );
};

export default ProfilePage;
