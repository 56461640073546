import React from 'react';
import Main from '../../components/main/Main';
import Sidebar from '../../components/sidebar/Sidebar';

const Home = () => {
  return (
    <div>
      <div className='MainContainer' style={{backgroundColor: "#f2f2f2"}} >
        {/* <Sidebar /> */}
        <Main />
      </div>
    </div>
  );
};

export default Home;
