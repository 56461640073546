import React, { useRef, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Box, Button, InputLabel } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TopicsService from '../../services/topics.service'
// import NativeSelect from '@material-ui/core/NativeSelect'
import axios from 'axios';
import AuthService from '../../services/AuthService';
import { useHistory } from "react-router";
import {toast} from 'react-toastify'
import {Editor, EditorState, convertToRaw, convertFromRaw} from 'draft-js';


const styles = {
  editor: {
      border: '1px solid gray',
      minHeight: '6em'
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // overflow: 'hidden'
    padding: '1rem',
    '& .MuiGrid-root.MuiGrid-item': {
      margin: 'auto',
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  containerGrid: {},
  pageTitle: {
    color: '#444444',
    marginTop: '80px',
  },
  select: {
    marginLeft: '1rem',
    '& select': {
      padding: '10px 15px',
    },
  },
  submitBtn: {
    background: '#74b9be!important',
    color: '#fff!important',
    minWidth: '150px',
    height: 40,
  },
  cont: {
    '& .Mui-focused': {
      color: '#74b9be',
    },
    '& .Mui-focused fieldset': {
      borderColor: '#74b9be!important',
    },
  },
}));

const MyNotes = () => {

  const history = useHistory();
  const classes = useStyles();
  const el = useRef();
  const [file, setFile] = useState('');
  const [fileContent, setFileContent] = useState('')
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [noteInfo, setNoteInfo] = useState({
    topic: '',
    subtopic: '',
    description: '',
    // privacy: '',
    userid: '',
  });
  const [topics, setTopics] = useState([]);
  const editor = useRef(null)
  

  useEffect(() => {
    async function run() {
      const topics = await TopicsService.getTopics();

      setTopics(topics);
    }

    run();
  }, []);

  const username = AuthService.getCurrentUser().username
  useEffect(()=>{
    setNoteInfo({
      ...noteInfo,
      userid: username,
    });
  },[])
  
  // const topics = await TopicsService.getTopics()
  // console.log(topics)

  const handleChange = (event) => {
    const name = event.target.name;
    setNoteInfo({
      ...noteInfo,
      [name]: event.target.value,
    });
  };

  const handleEditorChange = (editorState) => {
    setEditorState(editorState)

    const content = JSON.stringify(convertToRaw(editorState.getCurrentContent()));

    setFileContent(content)
    console.log("FILECONTENT: ",fileContent)
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0]; // accesing file
    setFile(file); // storing file
  };

  const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  const validatePayload=(payload)=>{
    
    for (const [key,value] of Object.entries(payload)) {
      if(!value){
        toast.warning(`${capitalize(key)} is mandatory`)
        // alert( `${key} is mandatory`);
        return false;
      }
    }
    return true;
  }

  const onSubmit = (event) => {
    // console.log("Onsubmit clicked")
    // const config = {
    //   headers: {
    //     'Content-Type': 'multipart/form-data',
    //   },
    // };
   
  
    const formData = new FormData();
    formData.append('file', fileContent);
    formData.append('state', JSON.stringify(noteInfo));
    // console.log(noteInfo)
    // formData.append('subtopic', state.subtopic);
    // formData.append('description', state.description);
    // formData.append('privacy', state.privacy);
    // console.log(formData);
    const payload = {...noteInfo, file: fileContent};
    if(validatePayload(payload)){
      
      axios
        .post('http://localhost:3001/mynotes/api/note', payload)
        .then((response) => {
          // console.log('Notes Submitted Response is ' + response);
          history.push("/")
          toast.success("Notes Submitted Successfully")
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  };

  return ( 
    <div className={classes.root}>
      <Box textAlign='center' padding='1rem'>
        <h1 className={classes.pageTitle}>My Notes</h1>
      </Box>
      <form
        className='form-c'
        noValidate
        autoComplete='off'
        // onSubmit={onSubmit}
        >
        <Grid className={classes.cont} container spacing={3}>
          <Grid item xs={12} sm={12}>
            {/* <TextField
              fullWidth
              id='outlined-basic'
              label='Topic'
              variant='outlined'
              name='topic'
              onChange={handleChange}
            /> */}
            <InputLabel id="demo-simple-select-label">Topic</InputLabel>
          <Select
          required
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            // value={noteInfo.topic}
            name="topic"
            onChange={handleChange}
          >
            
            <MenuItem disabled >Select a topic</MenuItem>
            {topics.map((topic)=>{
              return <MenuItem key = {topic._id} value={topic.name}>{topic.name}</MenuItem>
            })}
        </Select>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
            required
              fullWidth
              id='outlined-basic'
              label='Sub Topic'
              variant='outlined'
              name='subtopic'
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              fullWidth
              id='outlined-basic'
              label='Description'
              variant='outlined'
              name='description'
              onChange={handleChange}
            />
          </Grid>
          {/* <Grid item xs={12} sm={12}>
            <Box display='flex' alignItems='center'>
              <InputLabel htmlFor='age-native-simple'>
                Modify Privacy setting:{' '}
              </InputLabel>
              
              <Select
                className={classes.select}
                native
                value={state.age}
                onChange={handleChange}>
                <option aria-label="None" value="slecte" />
                <option value={10}>Private</option>
                <option value={20}>Public</option>
              </Select>
            </Box>
          </Grid> */}
          <Grid item xs={12} sm={12}>
            <div style={styles.editor}>
              <Editor 
                    ref = {editor}
                    textAlignment='left'
                    onChange={handleEditorChange}
                    editorState = {editorState}
                    placeholder="Write your notes here:"
                />
            </div>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Button className={classes.submitBtn} onClick={onSubmit}>
              Upload
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default MyNotes;
