import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import {Editor, EditorState, convertFromRaw} from 'draft-js';

import {
  Grid,
  Typography,
  Box,
  Button,
  TextField,
  Container,
  CssBaseline,
} from '@material-ui/core';


const editorStyles = {
  editor: {
      // border: '1px solid gray',
      minHeight: '6em'
  }
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  paper: {
    // marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  submitBtn: {
    background: '#74b9be!important',
    color: '#fff!important',
    minWidth: '150px',
    height: 40,
    marginTop: '20px',
  },
  textArea: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    color: 'black',
    padding: '10px',
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.00938em',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function CustomizedDialogs({ open, handleClose, classes, note }) {
  const history = useHistory();
  if (!note) {
    // history.push('/');
    return null;
  }
  const { topic, subtopic, description, fileData } = note;
  console.log("NOTE OBJECT: ", note)
  let editorState = EditorState.createEmpty();
  if(open){
    editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(fileData)))
  }

  
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        fullWidth
        maxWidth='lg'
        open={open}>
        <DialogTitle id='customized-dialog-title' onClose={handleClose}>
        Topic: {topic} | Subtopic: {subtopic}
        </DialogTitle>
        <DialogContent dividers>
          <Container component='main' maxWidth='lg'>
            <CssBaseline />
            <div className={classes.paper}>
              <form className={classes.form} noValidate>
                {/* <TextField
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  id='topic'
                  label='Topic'
                  name='topic'
                  autoComplete='topic'
                  autoFocus
                  value={topic}
                /> */}
                {/* <TextField
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  name='subTopic'
                  label='Sub Topic'
                  value={subtopic}
                  id='subTopic'
                  autoComplete='subTopic'
                /> */}
                {/* <TextField
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  name='description'
                  label='Description'
                  value={description}
                  id='description'
                  autoComplete='description'
                /> */}
                <strong>Description: {description}</strong>
                <div>
                
      
                  <br></br>
                  {/* {fileData} */}
                  <div style={editorStyles.editor}>
                  <Editor
                    editorState={editorState}
                    readOnly={true}
                  />
                  </div>
                  <br></br>
                  <br></br>  
                
                </div>

                {/* <TextField
                  id='outlined-multiline-static'
                  label='File Data'
                  multiline
                  fullWidth
                  className={classes.form}
                  rows={10}
                  defaultValue={fileData}
                  variant='outlined'
                /> */}
                {/* <Grid alignContent='center' item xs={12} sm={12}>
                  <Typography variant='h3' components='h4'>
                    {' '}
                    Comments
                  </Typography>
                  {note.comments?.map((fe, index) => {
                    console.log('%cfe', 'color:green;font-size:30px;', fe);
                    return (
                      <div key={index} className={` chatSection`}>
                        {note.comments?.[index].map((dataItm) => {
                          return (
                            <ul>
                              <li>
                                <span className='chatUserName'>Username:</span>
                                <span className='chatText'>
                                  {dataItm.comment}
                                </span>
                              </li>
                            </ul>
                          );
                        })}
                      </div>
                    );
                  })}
                </Grid> */}
              </form>
            </div>
          </Container>
        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={handleClose} color='primary'>
            Save changes
          </Button> */}
          {/* <Button
            onClick={handleClose}
            className={classes.submitBtn}
            type='submit'>
            Edit Note
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default withStyles(styles)(CustomizedDialogs);
