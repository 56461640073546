import { Box, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import React from 'react'
import EmailIcon from '@material-ui/icons/Email'

const Profile = () => {
    const userdetails = JSON.parse(localStorage.getItem("user"))
    // console.log("userDetails :", userdetails);
    return (
        <div className="profile">
            <div className="profile-name">
                <Box textAlign="center">
                    <h1>
                        {userdetails.username}
                    </h1>
                    <small>
                        {userdetails.email}
                    </small>
                </Box>
            </div>


        </div>
    )
}
export default Profile
