import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import TopicsService from '../../services/topics.service';

const useStyles = makeStyles((theme) => ({
  scroller: {
    padding: theme.spacing(2),
    display: 'flex',
    // overflowX: 'scroll',
    overflow: 'auto',
    position: 'fixed',
    width: 'inherit',
    top: '95px',
    background: 'azure',
    zIndex: '+1'
  },
  chip: {
    margin: theme.spacing(0, 1),
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const Filters = ({ onSetFilter, notes }) => {
  const [isLoading, setLoading] = useState(true);

  const [topics, setTopics] = useState([]);

  const [filter, setFilter] = useState('');

  const classes = useStyles();

  useEffect(() => {
    async function run() {
      const topics = await TopicsService.getTopics();

      setTopics(topics);

      setLoading(false);
    }

    run();
  }, [notes]);

  const isEqual = (a, b) => String(a) === String(b);

  const handleTopicSelect = (name) => {
    setFilter(name);
    onSetFilter(name);
  };

  if (isLoading) return <span>Loading....</span>;

  if (topics.length <= 0) return null;

  return (
    <div className={classes.scroller}>
      <Chip
        variant={isEqual('', filter) ? 'default' : 'outlined'}
        color='primary'
        label={'All'}
        onClick={() => handleTopicSelect('')}
        className={classes.chip}
      />
      {topics.map(({ name }) => (
        <Chip
          variant={isEqual(name, filter) ? 'default' : 'outlined'}
          color='primary'
          key={name}
          label={name}
          onClick={() => handleTopicSelect(name)}
          className={classes.chip}
        />
      ))}
    </div>
  );
};

export default Filters;
