import React from 'react'
import Calendar from './Calender'
import TodoList from './TodoList'

const Sidebar = () => {
    return (
        <aside className="sidebar">
            <TodoList />
            <Calendar />
        </aside>
    )
}

export default Sidebar
