import axios from 'axios';

const API_URL = 'https://noted-back.herokuapp.com/api/topic';

class TopicsService {
    async getTopics() {
        const { data } = await axios.get(API_URL);

        return data;
    }
}

export default new TopicsService();