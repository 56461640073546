import React, { useState } from 'react'
import Calendar from 'react-calendar'
import Slider from "react-slick"

const Calender = () => {
    const [value, onChange] = useState(new Date())
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1290,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,

                }
            },
            {
                breakpoint: 1050,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,

                }
            },
            {
                breakpoint: 770,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,

                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,

                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,

                }
            },
        ]
    }
    return (
        <div className="calender">
            <Calendar
                onChange={onChange}
                value={value}
            />
            <div className="events">
                <h3 className="event-h">EVENTS</h3>
                <Slider {...settings}>
                    <div className="eventItemContainer">
                        <div className="eventItem">
                            <p className="eventDetails">
                                Lorem ipsum dolor sit amet
                            </p>
                            <div className="eventTime">
                                10/02/2021 <span>09:35</span>
                            </div>
                        </div>
                    </div>
                    <div className="eventItemContainer">
                        <div className="eventItem">
                            <p className="eventDetails">
                                Lorem ipsum dolor sit amet
                            </p>
                            <div className="eventTime">
                                10/02/2021 <span>09:35</span>
                            </div>
                        </div>
                    </div>
                    <div className="eventItemContainer">
                        <div className="eventItem">
                            <p className="eventDetails">
                                Lorem ipsum dolor sit amet
                            </p>
                            <div className="eventTime">
                                10/02/2021 <span>09:35</span>
                            </div>
                        </div>
                    </div>
                    <div className="eventItemContainer">
                        <div className="eventItem">
                            <p className="eventDetails">
                                Lorem ipsum dolor sit amet
                            </p>
                            <div className="eventTime">
                                10/02/2021 <span>09:35</span>
                            </div>
                        </div>
                    </div>
                    <div className="eventItemContainer">
                        <div className="eventItem">
                            <p className="eventDetails">
                                Lorem ipsum dolor sit amet
                            </p>
                            <div className="eventTime">
                                10/02/2021 <span>09:35</span>
                            </div>
                        </div>
                    </div>
                    <div className="eventItemContainer">
                        <div className="eventItem">
                            <p className="eventDetails">
                                Lorem ipsum dolor sit amet
                            </p>
                            <div className="eventTime">
                                10/02/2021 <span>09:35</span>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
        </div>
    )
}

export default Calender
