import React from 'react'
import MyNotes from '../../components/myNotes/MyNotes'


const Notes = () => {
    return (
        <div>
            <div className="NotesContainer">
                <MyNotes />
            </div>
        </div>
    )
}

export default Notes
