import {Editor, EditorState, convertFromRaw} from 'draft-js';
import React, { useEffect, useState } from 'react';

const styles = {
  editor: {
      // border: '1px solid gray',
      minHeight: '6em'
  }
}

const Note = ({ note: { _id, topic, subtopic, fileData } }) => {
  // const[editorState, setEditorState] = useState(EditorState.createEmpty())
    const editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(fileData)))
    console.log("FILE DATA", JSON.parse(fileData))

  return (
    <div className='textArea'>
      <div className='text-wraper'>
        <b>
          <span style={{ color: '#1769aa' }}>Topic: {topic}</span>
        </b>
        <br />
        <b>
          <span style={{ color: '#1769aa' }}>Sub-Topic: {subtopic}</span>
        </b>
        <div className='username-opt'></div>
        {/* <p>{fileData}</p> */}
       <div style={styles.editor}>
        <Editor
          editorState={editorState}
          readOnly={true}
        />
        </div>
      </div>
    </div>
  );
};

export default Note;
