import React from 'react';
import Login from '../../components/Auth/Login';

const LoginPage = () => {
  return (
    <div>
      <div style={{ paddingTop: '150px' }} className='NotesContainer'>
        <Login />
      </div>
    </div>
  );
};

export default LoginPage;
