import React, {useState, Component} from 'react'
import { ReactComponent as HomeIcon } from '../../svg/home.svg'
import { ReactComponent as MailIcon } from '../../svg/mail.svg'
import { ReactComponent as ProfileIcon } from '../../svg/profile.svg'
import { ReactComponent as SandTimerIcon } from '../../svg/sandTimer.svg'
import { ReactComponent as ListIcon } from '../../svg/list.svg'
import { Link } from 'react-router-dom'
import AuthService from '../../services/AuthService'
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import {toast} from 'react-toastify'

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: AuthService.getCurrentUser()
          };
    }
    redirectToLogin = () => {
        AuthService.logout();
        const { history } = this.props;
        history.push('/Login');
        toast.success("Logout Successful")
        // window.location.reload();
    }
    render()
    {
        const { currentUser } = this.state;

        return (
            <header className="header">
                <div className="brand-logo">
                    <h2>NOTECADEMY</h2>
                </div>
                {/*<div className="timer">
                    {/* <SandTimerIcon /> */}
                    {/* <span className="timer-details">
                        <Link>New Here?</Link>
                    </span>
     
                </div> */}
                <ul className="nav-list">
                {AuthService.isAuthenticated() === false ? <span></span>:
                    <li>
                        <Link to="/notes">
                            <ListIcon />
                        </Link>
                    </li>
                }
                    <li>
                        <Link to="/">
                            <HomeIcon />
                        </Link>
                    </li>
                    {AuthService.isAuthenticated() === false ? <span></span>:
                    <li>
                        <Link to="/profile">
                            <ProfileIcon />
                        </Link>
                    </li>
                    }
                    
                    <li >
                    {
                    AuthService.isAuthenticated() === false ?
                        <Link to="/login">
                        <Button variant="contained">
                            Login
                        </Button>
                        </Link>
                        :
                            <p>
                                <Button variant="contained" onClick={this.redirectToLogin}>
                                Logout
                                </Button>
                            </p> 
                        }
                    </li>
                </ul>
            </header>
        )
    }
}


// const Header = () => {

//     return (
//         <header className="header">
//             <div className="brand-logo">
//                 <h2>NOTEACADEMY</h2>
//             </div>
//             <div className="timer">
//                 <SandTimerIcon />
//                 <span className="timer-details">
//                     Study Time Left: <span><strong>30 min</strong></span>
//                 </span>

//             </div>
//             <ul className="nav-list">
//                 <li>
//                     <Link to="/notes">
//                         <ListIcon />
//                     </Link>
//                 </li>
//                 <li>
//                     <Link to="/">
//                         <HomeIcon />
//                     </Link>
//                 </li>
//                 <li>
//                     <Link to="/">
//                         <MailIcon />
//                     </Link>
//                 </li>
//                 <li>
//                     <Link to="/profile">
//                         <ProfileIcon />

//                     </Link>
//                 </li>
//                 <li >
//                     {AuthService.getToken != false ? console.log(" logged ") : console.log("Not logged") }
//                     {
//                 AuthService.isAuthenticated() === true ?
//                     <Link to="/login">
//                         <span style={{ color: '#f1f1f1' }}>Login</span>
//                     </Link>
//                     :
//                     <span>Logout</span>
//                     }
//                 </li>
//             </ul>
//         </header>
//     )
// }

export default withRouter(Header)
