import axios from "axios";
import {toast} from 'react-toastify'

const API_URL = "https://noted-back.herokuapp.com/api/auth/";

class AuthService {
  login(userid, password) {
    return axios
      .post(API_URL + "signin", {
        userid,
        password
      })
      .then(response => {
        if(response.status=== 200){
          toast.success("Login Successful")
          // console.log("login successful")
        }

        if (response.data.accessToken) {
          // console.log(response.data)
          // console.log("Value is "+JSON.stringify(response.data.accessToken));
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data.accessToken;
      }).catch(err =>{
        toast.error(err.response.data.message)
      });
  }

  getToken()
  {
    if(localStorage.getItem("user"))
    {
      return localStorage.getItem("user");
    }
    else {
      return false;
    }
  }
  isAuthenticated()
  {
    if(localStorage.getItem("user"))
    {
      // console.log(" Authenticatd ");
      return true;
    }
    else {
      // console.log(" NOt Authenticatd ")

      return false;
    }
  }

  logout() 
  {
    if(this.isAuthenticated)
    {
      localStorage.removeItem("user")
      return true;
    } else {
      return false;
    }
  }

  register(username, email, password) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }


}

export default new AuthService();